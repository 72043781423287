import './navbar.fa';
import './navbar.scss';

$(() => {
    $('.site-search-btn').on('click', () => {
        $('.site-search').slideToggle(50, () => {
            $('.site-search input').first().trigger('focus');
        });
    });
});
